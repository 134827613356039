import React from 'react';
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  0% {
    opacity: 0;
		margin-left:20px;
  }
  100% {
    opacity: 1;
		margin-left:0;
  }
`

const Title = styled.h1`
	margin-top:0;
	line-height:1.2;	
`;
const FooterTitle = styled.div`
	margin-top:5px;
	margin-bottom:5px;
	text-transform:uppercase;
	color:${props => props.theme.red};
	line-height:2.8rem;
	font-size:2.2rem;
	display:flex;
	justify-items:center;
	align-items:center;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		display:block;
		width:100%;
	}
`;
const SpanWhite = styled.span`
	color:#fff;
	animation: ${props => props.duration} ${slideIn} ease-in-out;
`;
const SpanRed = styled.span`
	color:${props => props.theme.red};
	animation: ${props => props.duration} ${slideIn} ease-in-out;
`;

export const HomeTitle = () => {
	return (
		<Title>
			<SpanWhite duration="350ms">Box </SpanWhite> 
			<SpanRed duration="700ms">Architectural </SpanRed>
			<SpanRed duration="1050ms">Services </SpanRed>
			<SpanWhite duration="1300ms">Ltd </SpanWhite> 
		</Title>
	);
};

export const AboutTitle = () => {
	return (
		<Title>
			<SpanWhite duration="350ms">About </SpanWhite>
			<SpanWhite duration="700ms">Box </SpanWhite> 
			<SpanRed duration="1000ms">Architectural&nbsp;</SpanRed>
			<SpanRed duration="1300ms">Services </SpanRed>
			<SpanWhite duration="1500ms">Ltd </SpanWhite> 
		</Title>
	);
};

export const PortfolioTitle = () => {
	return (
		<Title>
			<SpanWhite duration="350ms">Our </SpanWhite>
			<SpanWhite duration="700ms">Work</SpanWhite> 
		</Title>
	);
};
export const TestimonialsTitle = () => {
	return (
		<Title>
			<SpanWhite duration="350ms">What </SpanWhite>
			<SpanWhite duration="700ms">Our </SpanWhite> 
			<SpanWhite duration="1000ms">Clients </SpanWhite>
			<SpanWhite duration="1300ms">Say </SpanWhite>
		</Title>
	);
};
export const ServicesTitle = () => {
	return (
		<Title>
			<SpanWhite duration="350ms">Services </SpanWhite>
			<SpanWhite duration="700ms">We </SpanWhite> 
			<SpanWhite duration="1000ms">Offer </SpanWhite>
		</Title>
	);
};
export const ContactTitle = () => {
	return (
		<Title>
			<SpanWhite duration="350ms">Get </SpanWhite>
			<SpanWhite duration="700ms">In </SpanWhite> 
			<SpanWhite duration="1000ms">Touch </SpanWhite>
		</Title>
	);
};

export const FooterStrap = () => {
	// Planning, Architectural & SAP&nbsp;Consultant
	return (
		<FooterTitle>
			<SpanRed duration="350ms">Planning </SpanRed>
			<SpanRed duration="700ms">Architectural </SpanRed> 
			<SpanRed duration="850ms">Architectural </SpanRed>
			<SpanRed duration="1000ms">&amp; </SpanRed>
			<SpanRed duration="1300ms">SAP&nbsp;</SpanRed>
			<SpanRed duration="1600ms">Consultant</SpanRed>
		</FooterTitle>
	);
};

export const ProjectTitle = ({title}) => {
	return (
		<Title>
			<SpanRed duration="350ms">PROJECT: </SpanRed>
			<SpanWhite duration="700ms">{title}</SpanWhite> 
		</Title>
	);
};
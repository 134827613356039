import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image";
import { ProjectTitle } from "../components/titles/Title";
import FadeIn from "../components/FadeIn";

const Wrapper = styled.div`
	display:flex;
	flex-direction:column;
	width:100%;
	flex:1 0 auto;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column
	}
`;
const ProjectDetails = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  p {
    margin: 5px 0;
  }
`;

const MasonryGrid = styled.div`
  column-count: 3;
  column-gap: 1rem;

  @media only screen and (${(props) => props.theme.mobileThreshold}) {
    column-count: 1;
  }
`;

const MasonryItem = styled.div`
  margin-bottom: 1rem;
  break-inside: avoid;
`;

const BackButton = styled.button`
  border: none;
	background: none;
  padding: 10px 0;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 20px;
	display: inline-block;
  width: fit-content; 
  max-width: max-content; 
  color:${props => props.theme.grey};
	text-decoration: none;
	white-space: nowrap;
	transition:${props => props.theme.trans};
  &:hover {
    color:${props => props.theme.red};
  }
`;
const StrongTitle = styled.strong`
  color:${props => props.theme.red};
`;
const Project = ({ pageContext: { project } }) => {

	// Extract category from URL query parameters
	const params = new URLSearchParams(typeof window !== "undefined" ? window.location.search : "");
	const category = params.get("category") || "New Builds"; // Default to "New Builds" if no category is found

	const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

	// Filter images for the current project
  const projectImages = data.allFile.nodes
    .filter((node) => node.relativePath.startsWith(`portfolio/${project.id}/`))
    .sort((a, b) => {
      // Extract numbers from filenames (e.g., "P048-03" → 3)
      const numA = parseInt(a.relativePath.match(/\d+/g)?.pop() || "0", 10);
      const numB = parseInt(b.relativePath.match(/\d+/g)?.pop() || "0", 10);

      return numA - numB; // Sort numerically
    });

  return (
		<Layout>
			<SEO title="Boxas - Projects" />
			<Wrapper>
				<BackButton onClick={() => navigate(`/projects?category=${encodeURIComponent(category)}`)}>
          ← Back to Projects
        </BackButton>

				<ProjectDetails>
					<ProjectTitle title={project.title} />
					<FadeIn duration="700" delay="1000">
						<p>
							<StrongTitle>Category:</StrongTitle> {project.category}
						</p>
						{/* <p>
							<StrongTitle>Date:</StrongTitle> {project.date}
						</p> */}
						<p>
							<StrongTitle>Location:</StrongTitle> {project.location}
						</p>
						<p>
							<StrongTitle>Description:</StrongTitle> {project.description}
						</p>
					</FadeIn>
        </ProjectDetails>

				<FadeIn duration="700" delay="1300">
					<MasonryGrid>
						{projectImages.map((image) => (
							<MasonryItem key={image.relativePath}>
								<Img fluid={image.childImageSharp.fluid} alt={project.title} />
							</MasonryItem>
						))}
					</MasonryGrid>
				</FadeIn>

				
			</Wrapper>
		</Layout>
  );
};

export default Project;